var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "color-display" }, [
      _c("div", {
        staticClass: "color-select",
        style: "background-color:rgba(" + _vm.colorVal + ")",
        attrs: { id: "select" },
        on: { click: _vm.openPanel },
      }),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isSelect,
            expression: "isSelect",
          },
        ],
        staticClass: "color-panel",
        attrs: { id: "box" },
      },
      _vm._l(_vm.colorList, function (item, i) {
        return _c(
          "div",
          {
            key: i,
            staticClass: "color",
            style: "background:rgba(" + item.fontRgba + ")",
            on: {
              click: function ($event) {
                return _vm.selectColor(item)
              },
            },
          },
          [_c("div", { staticClass: "mask" })]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }